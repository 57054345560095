import { Link } from "react-router-dom";
import { getResourceUrl } from "../../Services/CommanFunctions";
import { FaHeart } from "react-icons/fa";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function TutorBlock({tutor,likeTutor,unLikeTutor,isTutorFav}) {

    const history=useHistory()

    return (


        <Link to={'#'} className="tutor-item">
            <div className="tutor-wrap" onClick={()=>history.push("/tutor/profile/" + tutor?._id)}>

                <div className="tutor-avatar">
                    <img src={tutor?.user_id?.propic ? getResourceUrl(tutor?.user_id?.propic): "images/no_profile_img.png"} alt=" " className="" height={75}  width={75} />
                </div>
                <div className="tutor-subject">
                    <h6>{tutor?.first_name} {tutor?.last_name}</h6>
                    <p>{tutor?.tutoring_experience}  | {tutor?.about}</p>
                    <ul>
                        {tutor?.subjects?.map(subject => <li>
                            {subject.title}
                        </li>)}
                        {/* <li>
                            Algebra
                        </li>
                        <li>
                            Science
                        </li>
                        <li>
                            Physics
                        </li>
                        <li>
                            Geography
                        </li> */}
                    </ul>
                </div>
            </div>
            <div className="tutor-action">
                <ul>
                    <li className="active">
                        {isTutorFav(tutor?._id)==undefined? <a href="javascript:void(0)" onClick={()=>likeTutor(tutor?._id)}><FaHeart  /></a>: <a href="javascript:void(0)" onClick={()=>unLikeTutor(isTutorFav(tutor?._id))}><FaHeart color='#ff8fab' /></a>}
                    </li>
                    <li><a href="javascript:void(0)"><i className="fa fa-comment-o"></i>
                    </a>
                    </li>
                </ul>
            </div>
        </Link>

    )



}


export default TutorBlock