import React, { useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Tabs,
  Tab,
  Dropdown,
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import TutorSideMenu from "./TutorSideMenu";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import FieldErrors from '../../Services/FieldErrors';
import { NotificationManager } from 'react-notifications';
import { Admin } from '../../Services/Admin';
import { Student } from '../../Services/Student';
import NotificationUI from '../common/NotificationUI';
import { getResourceUrl } from '../../Services/CommanFunctions';
import { getMaskedText } from '../../helpers/utils';

function TutorSettings() {
  const user = JSON.parse(localStorage.getItem('user'))
  const history = useHistory()
  const [masked,doMask]=useState(true)
  const [formData, setFormData] = useState({
    id: user?.tutor?._id,
    name: user?.name,
    first_name: user?.tutor?.first_name,
    last_name: user?.tutor?.last_name,
    email: user?.email,
    phone: user?.phone,
    display_logo: user?.tutor?.display_logo,
    school_id: user?.tutor?.school_id,
    org_type: user?.tutor?.org_type,
    highest_education_background: user?.tutor?.highest_education_background,
    tutoring_experience: user?.tutor?.tutoring_experience,
    timezone: user?.tutor?.timezone,
    about: user?.tutor?.about,
    status: user?.status,
    get_notification: user?.tutor?.get_notification,
    use_chat_feature: user?.tutor?.use_chat_feature,
  })
  const [fieldError, setFieldError] = useState()
  const [password, setPassword] = useState({
    password: '',
    confirm_password: '',
    error: null
  })
  const changeName = e => {
    e.preventDefault()
    const fullName = e.target.value.split(' ')
    setFormData({ ...formData, name: e.target.value, first_name: fullName[0], last_name: fullName[1] })
  }
  const formSubmit = e => {
    e.preventDefault()
    // 
    Student.update(formData, 'tutor/update_tutor').then(
      (data) => {
        ;
        if (data?.data) {
          user.name = data?.data?.first_name + " " + data?.data?.last_name
          user.phone = data?.data?.phone
          user.email = data?.data?.email
          user.tutor = data?.data
          user.timezone = formData?.timezone
          localStorage.setItem('user', JSON.stringify(user))
          NotificationManager.success(data.message, 'success!', 2000);
          window.location.reload()
          
          setFieldError(null)
          // setSubject(data.data)
        } else {
          setFieldError(data.errors.details)
        }

      },
      (error) => {
        NotificationManager.error(error.toString(), 'error!', 2000);
        // setError(error)
      }
    )
  }
  const changePassword = (e) => {
    e.preventDefault()
    Student.update({ ...password, _id: user._id }, "users/change_password").then(
      // (data) => {
      //     if (data?.data) {
      //         // localStorage.setItem('user', JSON.stringify(data?.data))
      //     }
      // }
      (data) => {
        if (!data.errors) {

          NotificationManager.success(data.message, 'success!', 2000);
          // setMessage(data.message)
          // history.push('/admin-dashboard');
        }
        else {
          // NotificationManager.error(data.message, 'error!', 2000);
          setPassword({ ...password, error: data.errors.details })

          // FieldErrors(data)
          // < fieldErrors errors = { data } />
        }

      }
    )


  }
  const selectedDay = val => {
    console.log(val);
  };
  const logout = () => {
    localStorage.clear()
    history.push('/tutor')
  }

  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <TutorSideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
              </Col>
              <Col md={12}>
                <div className="card">
                  <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="home" title="Account Settings">
                      <div className="account-setting">
                        <h5 className="text-muted">Basic Information</h5>
                        <Form onSubmit={formSubmit}>
                          <Row>
                          <Col md={12}>
                              <Form.Group className="mb-3" >
                                <Form.Label>Disable masking to edit</Form.Label>
                                <Form.Check type="switch" defaultChecked value={masked} onChange={(e)=>doMask(e.target.checked)} />
                                {/* <FieldErrors errors={fieldError} field="name" /> */}
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group className="mb-3" >
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" value={masked?getMaskedText(formData?.name,'name'): formData?.name} onChange={changeName} disabled={masked} />
                                <FieldErrors errors={fieldError} field="name" />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" value={masked?getMaskedText(formData?.email,'email'): formData?.email} onChange={e => setFormData({ ...formData, email: e.target.value })} disabled={masked}/>
                                <FieldErrors errors={fieldError} field="email" />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Highest Educational Background</Form.Label>
                                <Form.Select aria-label="Default select example" defaultValue={formData?.highest_education_background} onChange={e => setFormData({ ...formData, highest_education_background: e.target.value })} >
                                  <option value='NO-DEGREE'>NO DEGREE</option>
                                  <option value='K-12'>K-12</option>
                                  <option value="UG">UG</option>
                                  <option value="PG">PG</option>
                                  <option value="PHD">PHD</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control type="tel" value={masked?getMaskedText(formData?.phone,'phone'): formData?.phone} onChange={e => setFormData({ ...formData, phone: e.target.value })} disabled={masked}/>
                                <FieldErrors errors={fieldError} field="phone" />
                              </Form.Group>
                            </Col>
                            {/* <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>School/Company Name</Form.Label>
                                <Form.Select disabled aria-label="Default select example">
                                  <option value="1">Mather High School, IL</option>
                                  <option value="2">Marion High School, IL</option>
                                  <option value="3">Don Bosco School</option>
                                </Form.Select>
                              </Form.Group>
                            </Col> */}
                            <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Years Of Experience </Form.Label>
                                <Form.Select aria-label="Default select example" defaultValue={formData?.tutoring_experience} onChange={e => setFormData({ ...formData, tutoring_experience: e.target.value })} >
                                  <option value="0">NO&nbsp;EXPERIENCE/CURRENT STUDENT</option>
                                  <option value="less than one Year"> LESS THAN A YEAR </option>
                                  <option value="2-5 Years">2-5 Years </option>
                                  <option value="5+ Years">5+ Years </option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Timezone </Form.Label>
                                <Form.Select aria-label="Default select example" defaultValue={formData?.timezone} onChange={e => setFormData({ ...formData, timezone: e.target.value })} >
                                  <option disabled value=''>Select Time Zone*</option>
                                  <option value="EST">EST</option>
                                  <option value="CST">CST</option>
                                  <option value="PST">PST</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Form.Group className="form-group mb-3" as={Col} md="12">
                            <Form.Label>Personal Bio</Form.Label>
                            <Form.Control as="textarea" placeholder="About you...." rows="4" value={formData?.about} onChange={e => setFormData({ ...formData, about: e.target.value })} />
                          </Form.Group>
                          <hr />
                          <h5 className="text-muted">Accessbility</h5>
                          <div className="accessbility mb-3">
                            <Form.Check
                              type="checkbox"
                              id="two"
                              label="Get notifications"
                              checked={formData?.get_notification}
                              onChange={e => setFormData({ ...formData, get_notification: e.target.checked })}
                            />
                            <Form.Check
                              type="checkbox"
                              id="three"
                              label="Use chat feature"
                              checked={formData?.use_chat_feature}
                              onChange={e => setFormData({ ...formData, use_chat_feature: e.target.checked })}
                            />
                          </div>
                          <Button variant="primary" className="btn-pill" type="submit">
                            Save Changes
                          </Button>
                        </Form>

                        <Form>

                        </Form>
                        {/* <hr />
                        <h5 className="text-muted">Delete Accounts</h5>
                        <Button variant="danger" type="submit" className="mt-3 btn-pill" style={{ width: 200, }}>
                          Delete Account
                        </Button> */}
                      </div>
                    </Tab>
                    <Tab eventKey="profile" title="Privacy Settings">
                      <div className="account-setting">
                        <Form onSubmit={changePassword}>
                          <h5 className="text-muted">Change Password</h5>
                          {/* <Form.Group className="mb-3">
                            <Form.Label>Old Password</Form.Label>
                            <Form.Control type="text" value="Jane Doe" />
                          </Form.Group> */}
                          <Form.Group className="mb-3">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control type="password" value={password?.password} onChange={e => setPassword({ ...password, password: e.target.value })} />
                            <FieldErrors errors={password.error} field="password" />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control type="password" value={password?.confirm_password} onChange={e => setPassword({ ...password, confirm_password: e.target.value })} />
                            <FieldErrors errors={password.error} field="confirm_password" />
                          </Form.Group>
                          <Button variant="primary" type="submit">
                            Save Changes
                          </Button>
                        </Form>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </div>
          <div className="right">
            <div className="user-short-text">
              <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  <img src={user?.propic?getResourceUrl(user?.propic):"images/no_profile_img.png"} width={'100%'} height={'100%'} alt="" />
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} - {user?.tutor?.org_type == 'school' ? user?.tutor_profile?.school_name : user?.tutor_profile?.company_name}</h5>
                  <p><small>ID: {user?.tutor?.tutor_code}</small></p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default TutorSettings
