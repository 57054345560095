import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link } from "react-router-dom";
import Autocomplete from 'react-autocomplete'
import { Organization } from '../../Services/Organization';
import TutorSignupStepOne from './TutorSignupStepOne';
import TutorSignupStepTwo from './TutorSignupStepTwo';
import TutorSignupStepThree from './TutorSignupStepThree';
import TutorSignupStepFour from './TutorSignupStepFour';
import TutorSignupStepFive from './TutorSignupStepFive';
import TutorSignupStepSix from './TutorSignupStepSix';
import { buildUser } from '../../helpers/user-builder';

function TutorRegistration(props) {
  const data = props.location.state ? props.location.state : null
  const [step, setstep] = useState(1);
  let userData = buildUser(data)
  // if (data) {
  //   userData = buildUser(data)
  // }
  const [schlFormData, setSchlFormData] = useState({
    ...userData,
    school: "",
    school_id: "",
    school_name: "",
    tutoring_experience: "",
    // username: "",
    logo: "",
    // logo_url: "",
    display_logo: "yes",
    // first_name: "",
    // last_name: "",
    // email: "",
    phone: "",
    timezone: "",
    highest_education_background: "PHD",
    about: "",
    subjects: [],
  })


  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setstep(step - 1);
  };

  //set my subject
  const addYourSubject = e => {
    const { value, checked } = e.target;
    const { subjects } = schlFormData;
    if (checked) {
      setSchlFormData(prevState => ({
        ...prevState,
        subjects: [...subjects, value],
      }));

    } else {
      setSchlFormData(prevState => ({
        ...prevState,
        subjects: subjects.filter((e) => e !== value),
      }));

    }
  }


  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = input => e => {
    // input value from the form
    const { value } = e.target;

    //updating for data state taking previous state and then adding new value to create new object
    setSchlFormData(prevState => ({
      ...prevState,
      [input]: value
    }));
  }
  function handleImage(newValue) {
    setSchlFormData(prevState => ({
      ...prevState,
      ['logo']: newValue
    }));
  }

  function handleSchool(name, newValue) {
    setSchlFormData(prevState => ({
      ...prevState,
      [name]: newValue
    }));
  }
  const handleContactBy = input => e => {
    const { value, checked } = e.target;
    const { contact_by } = schlFormData;
    if (checked) {
      setSchlFormData(prevState => ({
        ...prevState,
        contact_by: [...contact_by, value],
      }));

    } else {
      setSchlFormData(prevState => ({
        ...prevState,
        contact_by: contact_by.filter((e) => e !== value),
      }));

    }
  }

  const [value, setValue] = useState('');
  const [error, setError] = useState();
  const [validated, setValidated] = useState(false);
  const [file, setFile] = useState();
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }



  switch (step) {
    case 1:
      return (
        <>
          <TutorSignupStepOne nextStep={nextStep} handleImage={handleImage} handleFormData={handleInputData} handleSchool={handleSchool} values={schlFormData} />
        </>
      )
    case 2:
      return (
        <>
          <TutorSignupStepTwo nextStep={nextStep} prevStep={prevStep} handleImage={handleImage} handleFormData={handleInputData} handleSchool={handleSchool} values={schlFormData} />
        </>
      )
    case 3:
      return (
        <>
          <TutorSignupStepThree nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} handleContactBy={handleContactBy} handleSchool={handleSchool} values={schlFormData} />
        </>
      )
    case 4:
      return (
        <>
          <TutorSignupStepFour nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={schlFormData} />
        </>
      )
    case 5:
      return (
        <>
          <TutorSignupStepFive nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={schlFormData} addYourSubject={addYourSubject} />
        </>
      )
    case 6:
      return (
        <>
          <TutorSignupStepSix nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={schlFormData} />
        </>
      )
    // never forget the default case, otherwise VS code would be mad!
    default:
    // do nothing
  }
}

export default TutorRegistration
